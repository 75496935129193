import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import OtpInput from 'react-otp-input';
import Box from '@mui/material/Box';
import { useMutation, useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import get from 'lodash.get';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Dialog, Link } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { colorBlue, colorGrey03, colorRed } from '../../layout/css/colors';
import {
  ButtonsContainerStyle,
  LoadingStyle,
  ModalStyle,
  otpInputStyle,
  StyledButton,
  TextComponentRowStyle,
  DidNotReceiveOTPStyle,
  WhyModalStyle
} from './OTPModal.styles';
import { MUTATION_AUTHENTICATE_OTP, QUERY_GENERATE_OTP } from './OTPModal.gql';
import useToken from '../../hooks/useToken';
import withAnalytics from '../../hoc/withAnalytics';
import ResendOtpModal from '../resendOtpModal/ResendOtpModal';
import { useAppState } from '../../hooks/useAppState';
import { stripError } from '../../lib/utils';
import { useTimer } from '../../hooks/useTimer';
import { getErrorDetails } from '../../lib/dictionary/errors';

const getOTPDetails = (data) => {
  const verificationCode = get(data, 'generateOTP.verificationCode');
  const iv = get(data, 'generateOTP.iv');
  return {
    verificationCode,
    iv
  };
};

const hasOTP = (otpDetails) => {
  const { otp, iv, verificationCode, bookingNumber } = otpDetails ?? {};
  return !!otp && !!iv && !!verificationCode && !!bookingNumber;
};

const hasIV = (otpDetails) => {
  const { iv, verificationCode } = otpDetails ?? {};
  return !!iv && !!verificationCode;
};

const OTPModal = (props) => {
  const { appState, setAppState } = useAppState();
  const { resetBookingTimer } = useTimer();
  const { otpDetailsMap } = appState;
  const { handleSetState, otpModalOpen } = props;
  const otpDetails = otpDetailsMap.get(otpModalOpen);
  const { token } = useToken();
  const [showResendOTPModal, setShowResendOTPModal] = useState(false);
  const emailAddress = localStorage.getItem('emailAddress');

  const [otp, setOTP] = useState('');
  const [modalError, setModalError] = useState(null);
  const [whyModal, setWhyModal] = useState(false);

  const { loading: generationLoading } = useQuery(QUERY_GENERATE_OTP, {
    variables: { token, bookingNumber: otpModalOpen, serviceName: 'PartnerHub' },
    fetchPolicy: 'cache-and-network',
    skip: hasOTP(otpDetails) || hasIV(otpDetails),
    onCompleted: (data) => {
      const { verificationCode, iv } = getOTPDetails(data);
      setAppState({
        otpDetailsMap: new Map(
          otpDetailsMap.set(otpModalOpen, {
            ...otpDetails,
            iv,
            verificationCode
          })
        )
      });
    },
    onError: (error) => {
      setModalError(stripError(error));
    }
  });
  const [authenticateOTP, { loading: authenticationLoading }] = useMutation(MUTATION_AUTHENTICATE_OTP);

  useEffect(() => {
    const authenticateHasOTP = async () => {
      const newOtpDetails = otpDetailsMap.get(otpModalOpen);
      if (newOtpDetails) {
        const { otp: newOtp, iv, verificationCode, bookingNumber } = newOtpDetails ?? {};
        if (bookingNumber === otpModalOpen) {
          const input = { verificationCode, otp: newOtp, emailAddress, iv };
          try {
            await authenticateOTP({
              variables: {
                input
              }
            });
            handleSetState({
              vccModalOpen: otpModalOpen,
              otpModalOpen: ''
            });
          } catch (error) {
            setModalError(stripError(error));
          }
        }
      }
    };
    authenticateHasOTP();
  }, [authenticateOTP, handleSetState, emailAddress, otpModalOpen, otpDetailsMap]);

  const handleClose = () => {
    handleSetState({
      otpModalOpen: ''
    });
    setAppState({
      otpDetailsMap: new Map(
        otpDetailsMap.set(otpModalOpen, {
          ...otpDetails
        })
      )
    });
  };

  const handleChange = (newOtp) => setOTP(newOtp);
  const handleVerify = async () => {
    const { verificationCode, iv } = otpDetails;
    const input = { otp, emailAddress, verificationCode, iv };

    try {
      await authenticateOTP({
        variables: {
          input
        }
      });
      setAppState({
        otpDetailsMap: new Map(
          otpDetailsMap.set(otpModalOpen, {
            iv,
            otp,
            verificationCode,
            bookingNumber: otpModalOpen
          })
        )
      });
      handleSetState({
        vccModalOpen: otpModalOpen,
        otpModalOpen: ''
      });
    } catch (error) {
      setModalError(stripError(error));
    }
  };

  const handleReload = () => {
    const otpModal = otpModalOpen;
    handleSetState({ otpModalOpen: '' });
    setTimeout(() => {
      handleSetState({ otpModalOpen: otpModal });
    }, 10);
  };

  const handleRequestNewOTP = () => {
    const otpModal = otpModalOpen;
    handleSetState({ otpModalOpen: '' });
    otpDetailsMap.delete(otpModalOpen);
    setAppState({ otpDetailsMap: new Map(otpDetailsMap) });
    resetBookingTimer(otpModal);
    setTimeout(() => {
      handleSetState({ otpModalOpen: otpModal });
    }, 10);
  };

  if (generationLoading || authenticationLoading)
    return (
      <Modal open onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={ModalStyle}>
          <Box sx={LoadingStyle}>
            <CircularProgress />
          </Box>
        </Box>
      </Modal>
    );

  if (modalError) {
    const isNotFound = modalError.toString().includes('not found');
    const isRetry = modalError.toString().includes('incorrect');
    const { errorTitle, errorMessage } = getErrorDetails(modalError);
    return (
      <Modal
        open
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        id="otp-modal-error"
      >
        <Box sx={ModalStyle}>
          <ErrorIcon style={{color: colorRed, marginBottom: '1rem'}} fontSize='large'/>
          <Typography id="modal-modal-title" variant="h6" component="h2" fontWeight="bold">
            {errorTitle}
          </Typography>
          <Typography id="modal-modal-description" sx={{ m: 2 }} color={colorGrey03} fontWeight="strong">
            {errorMessage}
          </Typography>
          {!isNotFound &&
            (isRetry ? (
              <StyledButton name={`retryButton-${otpModalOpen}`} primary="true" onClick={handleReload}>
                Retry
              </StyledButton>
            ) : (
              <StyledButton name={`requestAccessButton-${otpModalOpen}`} primary="true" onClick={handleRequestNewOTP}>
                Request Access
              </StyledButton>
            ))}
        </Box>
      </Modal>
    );
  }

  const handleDidNotReceiveOTP = () => {
    setShowResendOTPModal(true);
  };

  const handleCloseResendOTPModal = () => {
    setShowResendOTPModal(false);
  };

  return (
    <>
      {whyModal && (
        <Dialog open={whyModal} onClose={() => setWhyModal(false)}>
          <Box sx={WhyModalStyle}>
            <Typography id="modal-modal-description" color={colorGrey03} fontWeight="strong">
              The access to the Virtual Credit Card has been secured through an authorization code to better protect the
              sensitive information and for compliance.
            </Typography>
          </Box>
        </Dialog>
      )}
      <Modal
        open={!!otpModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        id="otp-modal"
      >
        <Box sx={ModalStyle}>
          <Box sx={TextComponentRowStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2" fontWeight="bold">
              Authentication Required&nbsp;
            </Typography>
            <Typography id="modal-modal-title" variant="h6" component="h2" fontWeight="bold">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link href="#" underline="always" color={colorBlue} onClick={() => setWhyModal(true)}>
                (why?)
              </Link>
            </Typography>
          </Box>
          <Box sx={TextComponentRowStyle}>
            <Typography id="modal-modal-description" color={colorGrey03} fontWeight="strong">
              <span>An email containing the required 6 digit authorization code has been sent to&nbsp;</span>
              <span style={{ color: colorBlue, fontWeight: 'bold' }}>{emailAddress}</span>
            </Typography>
          </Box>
          <Typography id="modal-modal-description" sx={{ mt: 2 }} color={colorGrey03} fontWeight="strong">
            {`
            Please enter the provided code here to access the VCC`}
          </Typography>
          <Box margin="20px">
            <OtpInput
              inputStyle={otpInputStyle}
              value={otp}
              onChange={handleChange}
              numInputs={6}
              renderInput={(renderInputProps) => {
                const {
                  autoComplete,
                  className,
                  inputMode,
                  maxLength,
                  onBlur,
                  onChange,
                  onFocus,
                  onInput,
                  onKeyDown,
                  onPaste,
                  placeholder,
                  ref,
                  style,
                  type,
                  value,
                  'aria-label': ariaLabel
                } = renderInputProps;
                return (
                  <input
                    autoComplete={autoComplete}
                    className={className}
                    inputMode={inputMode}
                    maxLength={maxLength}
                    onBlur={onBlur}
                    onChange={onChange}
                    onFocus={onFocus}
                    onInput={onInput}
                    onKeyDown={onKeyDown}
                    onPaste={onPaste}
                    placeholder={placeholder}
                    ref={ref}
                    style={style}
                    type={type}
                    value={value}
                    aria-label={ariaLabel}
                  />
                );
              }}
            />
          </Box>
          <Box sx={TextComponentRowStyle}>
            <Typography id="modal-modal-description" color={colorGrey03} fontWeight="strong">
              <span>
                The Passcode will be valid for the next{' '}
                <span style={{ color: colorBlue, fontWeight: 'bold' }}>30 minutes</span>. You may close this window and
                enter the code within the secure timeframe window.
              </span>
            </Typography>
          </Box>
          <Box
            sx={{
              width: '55ch',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              '& > :not(style)': { margin: 1 }
            }}
          >
            <Typography onClick={handleDidNotReceiveOTP} sx={DidNotReceiveOTPStyle}>
              {`Didn't receive code?`}
            </Typography>
          </Box>
          <Box sx={ButtonsContainerStyle} width={500}>
            <StyledButton onClick={handleClose}>Cancel</StyledButton>
            <StyledButton primary="true" onClick={handleVerify}>
              Verify
            </StyledButton>
          </Box>
        </Box>
      </Modal>
      <ResendOtpModal
        handleClose={handleCloseResendOTPModal}
        showResendOtpModal={showResendOTPModal}
        handleSetState={handleSetState}
        otpModalOpen={otpModalOpen}
      />
    </>
  );
};

OTPModal.propTypes = {
  handleSetState: PropTypes.func,
  otpModalOpen: PropTypes.string
};

OTPModal.defaultProps = {
  handleSetState: () => {},
  otpModalOpen: ''
};

export default withAnalytics()(OTPModal);
