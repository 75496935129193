import React, { useReducer, useState } from 'react';
import AdvancedSearch from '../../buildingBlocks/advancedSearch/AdvancedSearch';
import BookingTable from '../../buildingBlocks/bookingTable/BookingTable';
import DashboardOverview from '../../buildingBlocks/dashboardOverview/DashboardOverview';
import OTPModal from '../../buildingBlocks/otpModal/OTPModal';
import VCCModal from '../../buildingBlocks/vccModal/VCCModal';
import reducer from '../../reducer/state.reducer';
import Transition from '../../layout/animation/Transition';

const initialState = {
  vccModalOpen: '',
  otpModalOpen: '',
  searchInput: {
    top: 9,
    skip: 0,
    guestName: undefined,
    organizationId: undefined,
    bookingId: undefined,
    checkin: undefined,
    createdAt: undefined,
    todayOnly: false
  },
  pagination: {
    page: 0,
    rowsPerPage: 9
  }
};

const Default = () => {
  const landingBookingNumber = localStorage.getItem('bookingNumber');
  const isDirectBill = localStorage.getItem('isDirectBill');
  const [state, dispatch] = useReducer(reducer, initialState);
  const [initialBookingNumber, setInitialBookingNumber] = useState(landingBookingNumber ?? '');
  const { otpModalOpen, vccModalOpen, searchInput, pagination } = state;

  const handleSetState = (newValue) => {
    dispatch({
      type: 'SET_STATE',
      newValue
    });
  };

  return (
    <Transition>
      <DashboardOverview />
      <AdvancedSearch
        initialBookingNumber={initialBookingNumber}
        setInitialBookingNumber={setInitialBookingNumber}
        handleSetState={handleSetState}
        searchInput={searchInput}
        pagination={pagination}
      />
      <BookingTable
        isDirectBill={isDirectBill}
        handleSetState={handleSetState}
        searchInput={searchInput}
        pagination={pagination}
      />
      {vccModalOpen && !otpModalOpen && <VCCModal vccModalOpen={vccModalOpen} handleSetState={handleSetState} />}
      {otpModalOpen && <OTPModal otpModalOpen={otpModalOpen} handleSetState={handleSetState} />}
    </Transition>
  );
};

export default Default;
