import React from 'react';
import { get } from 'lodash';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { EventAvailable, EventNote, ArrowCircleUp, ArrowCircleDown } from '@mui/icons-material';
import { useQuery } from '@apollo/client';
import useToken from '../../hooks/useToken';
import { colorBurgundy, colorGrey03, colorWhite, logoColor3 } from '../../layout/css/colors';
import { DashboardHeaderStyle, DashboardStyle, DashboardCard } from './DashboardOverview.styles';
import { StyledH1 } from '../advancedSearch/AdvancedSearch.styles';
import { QUERY_DASHBOARD_OVERVIEW } from './DashboardOverview.gql';
import Transition from '../../layout/animation/Transition';
import { stripError } from '../../lib/utils';

const DashboardOverview = () => {
  const { token } = useToken();

  const { data, loading, error } = useQuery(QUERY_DASHBOARD_OVERVIEW, {
    variables: { token },
    fetchPolicy: 'cache-and-network'
  });

  const dashboardStats = get(data, 'PartnerHubQueries.partnerHubDashboardStats', {});
  const iconMapping = {
    totalLodgelinkBookings: EventAvailable,
    pendingBookings: EventNote,
    checkInToday: ArrowCircleUp,
    checkOutToday: ArrowCircleDown
  };

  const titleMapping = {
    totalLodgelinkBookings: 'Total LodgeLink Bookings',
    pendingBookings: 'Future Bookings',
    checkInToday: 'Bookings Checking In Today',
    checkOutToday: 'Bookings Checking Out Today'
  };

  return (
    <Transition>
      <Box bgcolor={colorWhite} margin="10px" marginTop="50px">
        <Box bgcolor={colorBurgundy} height="40px" sx={DashboardHeaderStyle}>
          <StyledH1 color={colorWhite} size="16px">
            Dashboard
          </StyledH1>
        </Box>
        <Box sx={DashboardStyle}>
          {error && (
            <Box sx={{ display: 'flex', justifyContent: 'center', m: 2, width: '100%' }}>{stripError(error)}</Box>
          )}
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', m: 2, width: '100%' }}>
              <CircularProgress />
            </Box>
          ) : (
            Object.entries(dashboardStats)
              .filter(([key]) => key !== '__typename')
              .map(([key]) => {
                const IconComponent = iconMapping[key];
                return (
                  <Box sx={DashboardCard} key={`item-${key}`}>
                    {IconComponent && <IconComponent style={{ color: logoColor3 }} fill={logoColor3} />}
                    <Box width="70%">
                      <Box fontSize="1.2rem">{dashboardStats[key]}</Box>
                      <Box color={colorGrey03} fontSize="0.9rem">
                        {titleMapping[key]}
                      </Box>
                    </Box>
                  </Box>
                );
              })
          )}
        </Box>
      </Box>
    </Transition>
  );
};

export default DashboardOverview;
