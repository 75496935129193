import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import get from 'lodash.get';
import debounce from 'lodash/debounce';
import AutoComplete from './AutoComplete';
import useToken from '../../hooks/useToken';

const GraphAutoComplete = (props) => {
  const { value, query, queryString, label, inputName, setValue, mapper, disabled, initialValue } = props;
  const { token } = useToken();
  // search term state
  const [searchTerm, setSearchTerm] = useState(initialValue ?? '');
  const debouncedSetSearchTerm = debounce(setSearchTerm, 500);
  const updateSearchTerm = useCallback(val => debouncedSetSearchTerm(val), []);
  // query to get suggestions
  const { data, loading } = useQuery(query, {
    variables: {
      token,
      [inputName]: searchTerm
    },
    fetchPolicy: 'cache-first',
    skip: !searchTerm
  });
  const graphData = get(data, queryString, []);
  const displayData = mapper(graphData);

  React.useEffect(() => {
    if (initialValue) setValue(displayData[0]);
  }, [data]);

  return (
    <AutoComplete
      value={value}
      name={inputName}
      loading={loading}
      data={displayData} // search suggestions returned
      initialTerm={searchTerm}
      updateSearchTerm={updateSearchTerm}
      label={label}
      setValue={setValue}
      disabled={disabled ?? false}
      initial={initialValue ? displayData : undefined}
    />
  );
};

GraphAutoComplete.propTypes = {
  value: PropTypes.shape({}),
  query: PropTypes.shape({}).isRequired,
  queryString: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
  setValue: PropTypes.func,
  mapper: PropTypes.func,
  disabled: PropTypes.bool,
  initialValue: PropTypes.string
};

GraphAutoComplete.defaultProps = {
  value: {
    id: 0,
    label: ''
  },
  disabled: false,
  initialValue: null,
  mapper: () => {},
  setValue: () => {}
};

export default GraphAutoComplete;
